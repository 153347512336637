import React from 'react'

import Layout from '../../components/en/Layout'
import SEO from '../../components/SEO'

const Contact = () => {
  return (
    <Layout>
      <SEO
        title="Contact"
        canonical={`${process.env.GATSBY_HOST_URL}/en/contact`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/pt/contato`}
      />

      <div className="contact">
        <h1>Contact</h1>

        <p>Contact us:</p>
        <p>
          Email: <b>contato@historiaislamica.com.br</b>
        </p>
      </div>
    </Layout>
  )
}

export default Contact
